export let stats: any = {
    received: "Ачааг Солонгост хүлээн авсан",
    departed: "Инчоны боомтоос тээвэрлэлтэнд гарлаа",
    arrivedChina: "Хятадаар дамжиж байна",
    arrived: "Улаанбаатарт ирсэн",
    delivered: "Ачааг хүлээлгэж өгсөн",
	receivedMongolia: "Ачааг Монголд хүлээн авсан",
	receivedKorea: "Ачаа Солонгост ирсэн"	
}

export let modes: any = {
    normal: "Энгийн",
    air: "Агаарын ачаа",
    express: "Экспресс",
    mobile: "Гар утас, зай",	
    international: "Олон улсын ачаа",		
}

export let paymentMethods: any = {
    mustPaid: "Төлбөр - Монголд төлөгдөнө",
    paid: "Төлбөр - Солонгост төлөгдсөн",
    waiting: "Төлбөр - Хүлээгдэж байна",
	paidInUlaanbaatar: "Төлбөр - Улаанбаатарт төлөгдсөн",
	driver: "Төлбөр - Жолооч тооцоо авсан",	
}

export let deliveryOptions: any = {
    uncertain: "Хүргэлт тодорхойгүй",
    delivery: "Монголд хүргэлттэй",
    noDelivery: "Хүргэлтгүй",
}

export let roles: any = {
    admin: "Админ",
	worker: "Захиалгын ажилтан",
    delivery: "Ачаа хүлээлгэн өгөгч",
    reporter: "Тайлагнагч",
}

export let paymentUtilities: any = {
    cash: "Бэлнээр",
    transaction: "Бэлэн бусаар",
}