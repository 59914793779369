import React, { useContext, useEffect, useState } from 'react';
import firebase from "../../firebase";
import { Link } from 'react-router-dom';
import { deliveryOptions, modes, paymentMethods, paymentUtilities, stats } from "../../enums";
import ListItem from './ListItem';
import { AuthContext } from '../AuthProvider';
import Select from '../elements/Select';
import XLSX from 'xlsx';
import Input from '../elements/Input';

const db = firebase.firestore();

function List() {
    const [packs, setPacks] = useState<any[]>([]);
    const [search, setSearch] = useState("");
    const [mode, setMode] = useState("");
    const [departTime, setDepartTime] = useState("");
    const [status, setState] = useState("");
    const [createdBy, setCreatedBy] = useState("");
    const [paymentMethod, setPaymentMethod] = useState("");
    const [createdAtFrom, setCreatedAtFrom] = useState("");
    const [createdAtTo, setCreatedAtTo] = useState("");
    const [users, setUsers] = useState<any>({});
    const { currentUser } = useContext(AuthContext);

    const get = () => {
        if (["admin", "reporter"].indexOf(currentUser?.role) !== -1) {
            let q: any = db.collection("packages");

            if (mode) q = q.where('mode', '==', mode);
            if (departTime) q = q.where('departTime', '==', departTime);
            else if (createdAtFrom || createdAtTo) {
                if (createdAtFrom) {
                    let dateFrom = new Date(createdAtFrom);
                    q = q.where('createdAt', '>=', dateFrom);
                }
                if (createdAtTo) {
                    let dateTo = new Date(createdAtTo);
                    q = q.where('createdAt', '<=', dateTo);
                }
            } else q = q.orderBy('createdAt', 'asc');
            if (status) q = q.where('status', '==', status);
            if (createdBy) q = q.where('createdBy', '==', createdBy);
            if (paymentMethod) q = q.where('paymentMethod', '==', paymentMethod);

            q = q.limit(200);

            q.get().then((snapshot: any) => {
                setPacks(snapshot.docs.map((pack: { id: any; data: () => any; }) => ({ id: pack.id, ...pack.data() })));
            });
        }
    }

    useEffect(() => {
        db.collection("users").get().then((snapshot) => {
            let u: any = {};
            snapshot.forEach((uss) => {
                u[uss.data()?.phoneNumber] = uss.data()?.phoneNumber;
            });
            setUsers(u);
        });
    }, []);

    useEffect(get, [mode, departTime, currentUser, status, createdAtFrom, createdAtTo, createdBy, paymentMethod]);

    useEffect(() => {
        if (search) {
            setPacks([]);
        } else {
            get();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps 		
    }, [search]);

    const doSearch = () => {
        
        if (search) {
            db.doc(`packages/${search}`).get().then((doc: any) => {
                if (doc.exists) {
                    setPacks([{
                        id: doc.id,
                        ...doc.data()
                    }]);
                } else {
                    let ps: any[] = [];
                    db.collection("packages").where('senderPhone', '==', search).get().then((snapshot: any) => {
                        ps = snapshot.docs.map((pack: { id: any; data: () => any; }) => ({ id: pack.id, ...pack.data() }));
                    }).then(() => {
                        db.collection("packages").where('receiverPhone', '==', search).get().then((snapshot: any) => {
                            ps = [...ps, ...snapshot.docs.map((pack: { id: any; data: () => any; }) => ({ id: pack.id, ...pack.data() }))];
                            setPacks(ps);
                        });
                    });
                }
            });
        } else {
            get();
        }
    }

    const exprt = () => {
        const wb = XLSX.utils.book_new();
        const data = packs.map((pack, i) => {
            return {
                "д\\д": i + 1,
                "Код": pack.id,
                "Төлөв": stats[pack.status],
                "Илгээгчийн нэр": pack.senderName,
                "Илгээгчийн утасны дугаар": pack.senderPhone,
                "Илгээгчийн хаяг": pack.senderAddress,
                "Хүлээн авагчийн нэр": pack.receiverName,
                "Хүлээн авагчийн утасны дугаар": pack.receiverPhone,
                "Хүлээн авагчийн хаяг": pack.receiverAddress,
                "Хүлээн авагчийн регистр": pack.receiverRegister,
                "Ачааны жин": parseFloat(pack.weight),
                "Цэвэр жин": pack.netWeight,
                "Үнийн дүн": parseInt(pack.price),
                "Төлбөрийн мэдээлэл": paymentMethods[pack.paymentMethod],
                "Монголд хүргэлттэй эсэх": deliveryOptions[pack.hasDelivery],
                "Ангилал": modes[pack.mode],
                "Гарах огноо": pack.departTime,
                "Бүртгэсэн": pack.createdBy,
                "Бүртгэсэн огноо": pack.createdAt,
                "Ачааны мэдээлэл": pack.desc,
                "Ачааны үнэлгээ": pack.value,
                "Шуудангийн төлбөр": pack.postfee,
                "Ачааны тоо хэмжээ": pack.quantity,
                "Тээврийн мэдээлэл": pack.notif,
                "Тэгбэ код": pack.tegbeCode,
                "Дэс дарааллын код": pack.orderCode,
                "Төлбөрийн хэрэгсэл": paymentUtilities[pack.paymentUtility],
            }
        });

        XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(data));
        return XLSX.writeFile(wb, ("Khishigcargo: " + new Date().toLocaleDateString() + ".xlsx"));
    }

    return (
        <>
            <div className="input-group mb-3">
                <input type="search" className="form-control" value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Ачааны дугаар эсвэл утасны дугаараар хайх" onKeyDown={(e) => e.key === "Enter" && doSearch()} />
                <div className="input-group-append">
                    <button className="btn btn-primary" onClick={doSearch}>
                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-search" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z" />
                            <path fillRule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z" />
                        </svg>
                    </button>
                </div>
            </div>
            {search && <>
                <small className="text-muted">
                    &#128269;  эсвэл <span className="badge badge-secondary">Enter</span> дарж хайна уу.<br />
                    Утасны дугаарыг "+" тэмдэг болон улсын кодтой нь оруулаарай &#128591;&nbsp;
                </small>
            </>}
            {["admin", "reporter"].indexOf(currentUser?.role) !== -1 && !search && <>
                <div className="row">
                    <div className="col">
                        <Select state={mode} setState={setMode} options={{ ...modes, "": "Бүгд" }} label="Ангилалаар шүүх" />
                    </div>
                    <div className="col">
                        <Input state={departTime} setState={setDepartTime} label="Гарах огноо" type="date" />
                    </div>
                    <div className="col">
                        <Select state={status} setState={setState} options={{ ...stats, "": "Бүгд" }} label="Төлөвөөр шүүх" />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <Select state={createdBy} setState={setCreatedBy} options={{ ...users, "": "Бүгд" }} label="Оруулсан ажилтан" />
                    </div>
                    <div className="col">
                        <Input state={createdAtFrom} setState={setCreatedAtFrom} label="Бүртгэсэн огноо <small>эхлэх</small>" type="date" />
                    </div>
                    <div className="col">
                        <Input state={createdAtTo} setState={setCreatedAtTo} label="Бүртгэсэн огноо <small>дуусах</small>" type="date" />
                    </div>
                    <div className="col">
                        <Select state={paymentMethod} setState={setPaymentMethod} options={{ ...paymentMethods, "": "Бүгд" }} label="Төлбөрийн мэдээлэл" />
                    </div>
                </div>
            </>}
            {["reporter", "admin"].indexOf(currentUser?.role) !== -1 && <>
                <button className="btn btn-info" onClick={exprt}>
                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-file-earmark-spreadsheet" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M5 10H3V9h10v1h-3v2h3v1h-3v2H9v-2H6v2H5v-2H3v-1h2v-2zm1 0v2h3v-2H6z" />
                        <path d="M4 0h5.5v1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h1V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z" />
                        <path d="M9.5 3V0L14 4.5h-3A1.5 1.5 0 0 1 9.5 3z" />
                    </svg>&nbsp;
                    Эксел файлаар гаргах
                </button>
            </>}
            {["worker", "admin", "reporter"].indexOf(currentUser?.role) !== -1 && <>
                <Link className="btn btn-primary float-right" to="/packages/create">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-plus-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                        <path fillRule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                    </svg>&nbsp;
                    Захиалга нэмэх
                </Link>
            </>}
            <br />
            &nbsp;
            <ul className="list-group mb-2">
                {packs.map((pack) => <ListItem pack={pack} key={pack.id} />)}
            </ul>
        </>
    );
}

export default List;