import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import firebase from "../../firebase";
import { AuthContext } from "../AuthProvider";
import { stats, modes, paymentMethods, deliveryOptions, paymentUtilities } from "../../enums";
import Input from "../elements/Input";
import InputWorker from "../elements/InputWorker";
import Select from "../elements/Select";
import SelectEditWorker from "../elements/SelectEditWorker";
import SelectEditPaymentMethod from "../elements/SelectEditPaymentMethod";
import PhoneInput from "../elements/PhoneInput";
import CurrencyEditWorker from "../elements/CurrencyEditWorker";
import Textarea from '../elements/Textarea';


const db = firebase.firestore();
db.settings({ ignoreUndefinedProperties: true });

function Edit() {
    const {id} = useParams<{id: string}>();
    const [trackId, setTrackId] = useState<string>(id);
    const [status, setStatus] = useState<string>(Object.keys(stats)[0]);
    const [senderName, setSenderName] = useState("");
    const [senderPhone, setSenderPhone] = useState("");
    const [senderAddress, setSenderAddress] = useState("");
    const [receiverName, setReceiverName] = useState("");
    const [receiverPhone, setReceiverPhone] = useState("");
    const [receiverAddress, setReceiverAddress] = useState("");
    const [receiverRegister, setReceiverRegister] = useState("");	
    const [weight, setWeight] = useState();
    const [netWeight, setNetWeight] = useState();	
    const [price, setPrice] = useState();
    const [paymentMethod, setPaymentMethod] = useState("");
    const [hasDelivery, setHasDelivery] = useState("");
    const [mode, setMode] = useState("");
    const [departTime, setDepartTime] = useState("");
    const [desc, setDesc] = useState("");
    const [value, setValue] = useState("");
    const [postfee, setPostfee] = useState(0);
    const [quantity, setQuantity] = useState(0);
    const [notif, setNotif] = useState("");
	const [tegbeCode, setTegbeCode] = useState("");
    const [orderCode, setOrderCode] = useState("");	
    const [paymentUtility, setPaymentUtility] = useState("");
    const [createdBy, setCreatedBy] = useState("");
    const { currentUser } = useContext(AuthContext);
    const [docRef] = useState<firebase.firestore.DocumentReference>(db.collection("packages").doc(id));
    const history = useHistory();

    useEffect(() => {
        docRef.get().then((doc) => {
            if (doc.exists) {
                let data = doc.data();
                setTrackId(doc.id);
                setSenderName(data?.senderName);
                setSenderPhone(data?.senderPhone);
                setSenderAddress(data?.senderAddress);
                setReceiverName(data?.receiverName);
                setReceiverPhone(data?.receiverPhone);
                setReceiverAddress(data?.receiverAddress);
                setReceiverRegister(data?.receiverRegister);				
                setWeight(data?.weight);
                setNetWeight(data?.netWeight);				
                setPrice(data?.price);
                setPaymentMethod(data?.paymentMethod);
                setHasDelivery(data?.hasDelivery);
                setMode(data?.mode || Object.keys(modes)[0]);
                setStatus(data?.status || Object.keys(stats)[0]);
                setDepartTime(data?.departTime);
                setDesc(data?.desc);
                setValue(data?.value);
                setPostfee(data?.postfee);
                setQuantity(data?.quantity);
                setNotif(data?.notif);
                setPaymentUtility(data?.paymentUtility);
				setTegbeCode(data?.tegbeCode);
				setOrderCode(data?.orderCode);
                setCreatedBy(data?.createdBy);
            }
        }).catch(console.error);
    }, [id, docRef]);

    const update = () => {
        docRef.update({
            status,
            senderName,
            senderPhone,
            senderAddress,
            receiverName,
            receiverPhone,
            receiverAddress,
			receiverRegister,
            weight,
			netWeight,
            price,
            paymentMethod,
            hasDelivery,
            mode,
            departTime,
            desc,
            value,
            postfee,
            quantity,
            notif,
            paymentUtility,
			tegbeCode,
			orderCode,
            updatedBy: currentUser?.uid,
        }).then(() => {
            history.push(`/packages/${id}`);
        }).catch(console.error);
    }

    const call = () => {
        let formData = new FormData();
        formData.append('number', receiverPhone);
        formData.append('token', 'NDim5Uwpre4tpWCleIfHpiyOCS5XJyII');
    
        fetch("https://pbx.khishigcargo.com/robodialer.php",
            {
                body: formData,
                method: "post"
            }            
            ).catch(e => console.log(e));

        alert("Тус хэрэглэгч рүү автоматаар залгаж мэдэгдэл хүргүүллээ.");
        
        return;

    }
      
    return (
        <>
            <small>Захиалгын дугаар: </small>
            <h3>{trackId}</h3>
                <div className="row">
                    <div className="col">
                        <Input state={senderName} setState={setSenderName} label="Илгээгчийн нэр" />
                    </div>
                    <div className="col">
                        <PhoneInput state={senderPhone} setState={setSenderPhone} label="Илгээгчийн утас" />
                    </div>
                </div>
                <Textarea state={senderAddress} setState={setSenderAddress} label="Илгээгчийн хаяг" />
                <div className="row">
                    <div className="col">
                        <Input state={receiverName} setState={setReceiverName} label="Хүлээн авагчийн нэр" />
                    </div>
                    <div className="col">
                        <PhoneInput state={receiverPhone} setState={setReceiverPhone} label="Хүлээн авагчийн утас" />
                    </div>
                </div>
                <Textarea state={receiverAddress} setState={setReceiverAddress} label="Хүлээн авагчийн хаяг" />
                <div className="row">
                    <div className="col">
                        <InputWorker state={weight} setState={setWeight} label="Жин" type="number" append="кг" />
                    </div>
					<div className="col">
                        <InputWorker state={netWeight} setState={setNetWeight} label="Цэвэр Жин" type="number" append="кг" />
                    </div>
                    <div className="col">
                        <CurrencyEditWorker state={price} setState={setPrice} label="Үнийн дүн" createdBy={createdBy}/>
                    </div>
                    <div className="col">
                        <Input state={departTime} setState={setDepartTime} label="Гарах огноо" type="date" />
                    </div>
                </div>
			
                <div className="row">
                        <div className="col">
                            <SelectEditPaymentMethod state={paymentMethod} setState={setPaymentMethod} label="Төлбөрийн мэдээлэл" options={paymentMethods} createdBy={createdBy}/>
                        </div>
                        <div className="col">
                            <Select state={paymentUtility} setState={setPaymentUtility} label="Төлбөрийн хэрэгсэл" options={paymentUtilities} />
                        </div>
                        <div className="col">
                            <Select state={hasDelivery} setState={setHasDelivery} label="Монголд хүргэлттэй эсэх" options={deliveryOptions} />
                        </div>
                </div>
			
                <div className="row">
                    <div className="col">
                        <Select state={mode} setState={setMode} label="Ангилал" options={modes} />
                    </div>
                    <div className="col">
					    <Input state={receiverRegister} setState={setReceiverRegister} label="Хүлээн авагчийн регистр" />
					</div>
                </div>
                <div className="row">
                    <div className="col">
                        <Input state={desc} setState={setDesc} label="Ачааны мэдээлэл" />
                    </div>
                    <div className="col">
                        <Input state={value} setState={setValue} label="Ачааны үнэлгээ" />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <Input state={tegbeCode} setState={setTegbeCode} label="Тэгбэ код" />
                    </div>
                    <div className="col">
                        <Input state={orderCode} setState={setOrderCode} label="Дэс дарааллын код" />
                    </div>
                </div>				
                <div className="row">
                    <div className="col">
                        <Input type="number" state={quantity} setState={setQuantity} label="Ачааны тоо хэмжээ" />
                    </div>
                    <div className="col">
                        <Input type="number" state={postfee} setState={setPostfee} label="Хүргэлтийн төлбөр" />
                    </div>
                </div>
				
                <Textarea state={notif} setState={setNotif} label="Тээврийн мэдээлэл" />
				
                <SelectEditWorker state={status} setState={setStatus} label="Төлөв" options={stats} />
				

            <Link className="btn btn-light" to={`/packages/${id}`}>
                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-caret-left" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M10 12.796L4.519 8 10 3.204v9.592zm-.659.753l-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z" />
                </svg>&nbsp;
                Буцах
            </Link>
            <button className="btn btn-primary float-center" onClick={call}>
                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-pencil" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                </svg>&nbsp;
                Авто мэдэгдэл өгөх
            </button>

            <button className="btn btn-primary float-right" onClick={update}>
                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-pencil" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                </svg>&nbsp;
                Шинэчлэх
            </button>

        </>
    );
}

export default Edit;